<template>
    <ValidationProvider
        :name="validationName"
        :rules="validationRules"
        v-slot="{ errors }"
    >
        <v-checkbox
            dense
            :disabled="inputDisabled"
            :error-messages="errors"
            hide-details="auto"
            :id="id"
            outlined
            :value="inputValue"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
        >
            <template v-slot:label>
                <span class="label-message grey--text text--darken-1">
                    {{ inputLabel }}
                </span>
            </template>
        </v-checkbox>
    </ValidationProvider>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    name: "customCheckBox",
    props: {
        id: { default: null, type: String },
        inputDisabled: { default: false, type: Boolean },
        inputLabel: { default: "DefaultLabel", type: String },
        inputValue: { default: true, type: Boolean },
        validationName: { default: "ValidationProviderName" },
        validationRules: { default: null },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

<style scoped>
.label-message {
    font-size: 0.9rem;
}
</style>
